import http, {Service} from './common'
const resource = 'address'
class AddressService extends Service {
  findByPhoneNumber(phoneNumber, groupName) {
    return http.get(`/${this.resource}?contactPhone=${phoneNumber}&groupName=${groupName}`).then(res => res.data);
  }
  import(infos, data) {
    return http.post(`/${this.resource}/import`, {...infos, data}).then(res => res.data);
  }
  findByCode(code) {
    return http.get(`/${resource}?code=${code}`)
  }

  findBySender(sender) {
    return http.get(`/${resource}?sender=${sender}`)
  }
}

export default new AddressService(resource)
