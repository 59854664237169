<template>
  <div>
    <slot>
    </slot>
    <slot name="message">
      <small class="p-error" v-if="message">{{message}}</small>
    </slot>
  </div>
</template>
<script>
export default {
  name:"form-field",
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    }
  },
  updated() {
    //console.log(this.message);
  },
  computed: {

  }
}
</script>
