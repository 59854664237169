import http, { Service } from "./common";
const resource = "geo";
class GeoInfoService extends Service {
  async getByCountry(country) {
    let cacheGeoInfos = localStorage.getItem(`geoinfo_${country}`);
    if (cacheGeoInfos) {
      let data =  JSON.parse(cacheGeoInfos);
      return data;
    } else {
      const res = await http.get(`/${this.resource}/all/${country}`);
      localStorage.setItem(`geoinfo_${country}`, JSON.stringify(res.data));
      return res.data;
    }
  }
  async loadFirstLevel(countries) {
    const res = await http.get(
      `/${this.resource}/firstLevel?countries=${countries}`
    );
    return res.data;
  }
  async import(infos, data) {
    const res = await http.post(`/${this.resource}/import`, { ...infos, data });
    return res.data;
  }
  findByCode(code) {
    return http.get(`/${resource}?code=${code}`);
  }

  findBySender(sender) {
    return http.get(`/${resource}?sender=${sender}`);
  }
}

export default new GeoInfoService(resource);
