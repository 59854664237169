<template>
  <AutoComplete v-model="selectedValue"
    :dropdown="dropdown"
    :suggestions="filteredValues"
    :placeholder="placeholder"
    :forceSelection="forceSelection"
    @item-select="onItemSelect"
    @input="handleInput"
    @complete="searchValue($event)"/>
</template>
<script>
//import {FilterService,FilterMatchMode} from 'primevue/api';
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    },
    dropdown: {
      type: Boolean,
      default: true
    },
    forceSelection: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "Hint: type some letter",
    },
    options: {
      type: Array,
      default() {
        return []
      }
    }
  },
  /*
  model: {
    prop: "value",
  },
  */
  data() {
    return {
      selectedValue: this.value,
      filteredValues: []
    }
  },
  methods: {
    /*
    updateValue(event) {
        this.$emit('update:modelValue', event.target.value);
    },
    */
    handleInput() {
      this.$emit("input", this.selectedValue);
    },
    onItemSelect(event) {
      this.$emit("item-select", event);
    },
    searchValue(event) {
      let query = event.query;
      setTimeout(() => {
          if (!query.trim().length) {
              this.filteredValues = [...this.options];
          }
          else {
              let filter = query.toLowerCase();
              this.filteredValues = this.options.filter((value) => {
                  return value.toLowerCase().includes(filter);
              });
          }
      }, 100);
    }
  }
}
</script>
